<template>
  <v-btn
    class="ml-3 mr-4"
    elevation="1"
    fab
    small
    @click="changeMini"
  >
    <v-icon>
      {{ this.mini ? 'mdi-format-list-bulleted' : 'mdi-dots-vertical' }}
    </v-icon>
  </v-btn>
</template>

<script>
  export default {
    name: 'DefaultDrawerToggle',
    data() {
      return {
      };
    },
    methods: {
      changeMini() {        
        this.$store.commit('changeMini', !this.mini);
      }
    },
    computed: {
      mini() {
        return this.$store.state.mini;
      }      
    }
  }
</script>
